<template>
  <div>
    <component
      v-for="(blok, index) in header"
      :key="index"
      :blok="blok"
      :is="blok.component"
    />
    <div><slot /></div>
    <Footer />
    <LazyScrollToTop v-if="scrollButton" />
    <LazyCountrySelector v-if="countrySelector" />
    <ClientOnly>
      <SnackBar />
    </ClientOnly>
    <ClientOnly>
      <ModalCartModal :visibility="showCart" />
    </ClientOnly>
    <ClientOnly>
      <SpinnerLoading />
    </ClientOnly>
  </div>
</template>

<script setup>
useHead({
  // Classes
  bodyAttrs: { class: { margin: 0 } },
})

const { countrySelector } = useCountrySelector()
const { cart } = useCart()

const scrollButton = ref(false)
const showCart = ref(cart)

const { data: dataHeader } = await useFetch(
  '/api/public/cms/published/global/header'
)

const { data } = useNuxtData('route')

const header = computed(() => {
  if (!dataHeader.value) return
  const items = []
  for (const itemHeader of dataHeader.value?.decoratedStory.raw) {
    if (!itemHeader?.pages) {
      items.push(itemHeader)
      continue
    } else if (itemHeader.pages.includes('all')) {
      items.push(itemHeader)
      continue
    }

    if (!data.value) {
      items.push(itemHeader)
      continue
    }

    switch (data.value?.response?.data?.name) {
      case 'productResult':
        if (itemHeader.pages.includes('product')) items.push(itemHeader)
        continue
      case 'categoryResult':
        if (itemHeader.pages.includes('category')) items.push(itemHeader)
        continue
      case 'sectionResult':
        if (itemHeader.pages.includes('section')) items.push(itemHeader)
        continue
      case 'manufacturerResult':
        if (itemHeader.pages.includes('manufacturer')) items.push(itemHeader)
        continue
    }
  }

  return items
})

onMounted(() => {
  let oldValue = 0
  let newValue = 0

  window.onscroll = () => {
    if (window.pageYOffset < 300) {
      scrollButton.value = false
      return
    }

    newValue = window.pageYOffset
    if (oldValue < newValue) {
      scrollButton.value = false
    } else if (oldValue > newValue) {
      scrollButton.value = true
    }
    oldValue = newValue
  }
})
</script>
